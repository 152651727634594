import { IResourceDirectoryApi } from './api';
import { FluentResource } from '@fluent/bundle';

export const useMessagesDirectory = (): IResourceDirectoryApi => ({
  async read(id) {
    const res = await fetch(
      `${id}?cacheInvalidator=${Date.now()}`
      /* , {
      headers: {
        'Cache-Control': 'no-cache',
      },
    } */
    );
    if (!res.ok) throw new Error(res.statusText);

    return new FluentResource(await res.text());
  },
});
