import { useMemo } from 'react';
import { useMessages } from '../../store/messages';
import { useObservations } from '../../store/observations';
import DebriefingSection from '../layout/debriefing-section';
import NonSoCheNomeDargli from '../plots/non-so-che-nome-dargli';
import { scenarioLabels } from '../plots/scenario-labels';

const PlanAnalyzerPt2Section = ({ groupId, sessionId }) => {
  const { find, observations } = useObservations(({ observations, find }) => ({
    observations,
    find,
  }));

  const t = useMessages(({ print }) => print);
  const formatNumber = useMessages(({ formatNumber }) => formatNumber);

  const sessionObservations = useMemo(
    () => find(`/sessions/${sessionId}/**/groups/**/plans/executive`),
    [find, observations, sessionId, groupId]
  );

  const groupObservation = sessionObservations.find(
    obs => obs.groupId === groupId
  );

  return (
    <DebriefingSection
      title={t('slide-7-title', { team: groupId || '' })}
      groupId={groupId}
      data={`${scenarioLabels[
        groupObservation?.scenario
      ]?.toUpperCase()} - AcR: ${formatNumber(
        Number(groupObservation?.acceptanceRate) * 100
      )}%`}
      subTitle={t('slide-7-tooltip')}
    >
      <NonSoCheNomeDargli sessionId={sessionId} groupId={groupId} />
    </DebriefingSection>
  );
};
export default PlanAnalyzerPt2Section;
