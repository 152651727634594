"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebriefParamsEncode = exports.DebriefParamsDecode = exports.QSEncode = exports.QSDecode = void 0;
const QSDecode = (encodedParam) => JSON.parse(Buffer.from(decodeURIComponent(encodedParam), "base64").toString("ascii"));
exports.QSDecode = QSDecode;
const QSEncode = (paramToEncode) => encodeURIComponent(Buffer.from(JSON.stringify(paramToEncode)).toString("base64"));
exports.QSEncode = QSEncode;
const DebriefParamsDecode = (encodedParam) => JSON.parse(Buffer.from(decodeURIComponent(encodedParam), "base64").toString("ascii"));
exports.DebriefParamsDecode = DebriefParamsDecode;
const DebriefParamsEncode = (paramToEncode) => encodeURIComponent(Buffer.from(JSON.stringify(paramToEncode)).toString("base64"));
exports.DebriefParamsEncode = DebriefParamsEncode;
