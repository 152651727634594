import { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useMessages } from '../../store/messages';
import { useObservations } from '../../store/observations';
import DebriefingSection from '../layout/debriefing-section';
import { scenarioLabels } from '../plots/scenario-labels';
import StepsQuality from '../plots/steps-quality';

interface IProps {
  sessionId: string;
  groupId: string;
}

// Slide 9
const StepsQualitySection = ({ sessionId, groupId }: IProps) => {
  const [showBenchmark, setShowBenchmark] = useState<boolean>(false);

  const { find, observations } = useObservations(({ find, observations }) => ({
    find,
    observations,
  }));

  const planObservations = useMemo(
    () => find(`/sessions/${sessionId}/**/groups/${groupId}/plans/executive`),
    [find, observations, sessionId, groupId]
  );

  const globalPlansObservations = useMemo(
    () => find('/sessions/**/**/groups/**/plans/executive'),
    [find, observations]
  );

  const t = useMessages(({ print }) => print);
  const formatNumber = useMessages(({ formatNumber }) => formatNumber);

  if (!groupId) return <p>No team selected.</p>;

  const groupObservation = planObservations.find(
    obs => obs.groupId === groupId
  );

  const toBenchmark = (acc, current, index, all) => ({
    ...acc,
    preparationPhasePerformance:
      (Number(acc.preparationPhasePerformance) +
        Number(current.preparationPhasePerformance)) /
      all.length,

    communicationPhasePerformance:
      (Number(acc.communicationPhasePerformance) +
        Number(current.communicationPhasePerformance)) /
      all.length,

    executionPhasePerformance:
      (Number(acc.executionPhasePerformance) +
        Number(current.executionPhasePerformance)) /
      all.length,
  });

  const benchmarkData = globalPlansObservations
    ?.filter(({ scenario }) => (scenario === groupObservation?.scenario))
    .reduce(toBenchmark, {
      preparationPhasePerformance: 0,
      communicationPhasePerformance: 0,
      executionPhasePerformance: 0,
  });

  console.dir({globalPlansObservations})

  return (
    <DebriefingSection
      title={t('slide-8-title')}
      data={`${scenarioLabels[
        groupObservation?.scenario
      ]?.toUpperCase()} - AcR: ${formatNumber(
        Number(groupObservation?.acceptanceRate) * 100
      )}%`}
      subTitle={t('slide-8-tooltip')}
    >
      <div className="mx-auto left-0 right-0 bg-white/50 rounded-2xl absolute top-1 px-8 py-2 w-64 flex flex-row justify-between items-center">
        <label className="uppercase text-slate-900 text-xs font-semibold opacity-90 peer">
        {t('show-benchmarks')}
        </label>
        <div className="absolute z-50 w-64 p-2 text-xs text-white transition-all duration-200 ease-in-out rounded-lg shadow-xl opacity-0 pointer-events-none -left-0 peer-hover:opacity-100 top-10 bg-black/80 backdrop-blur-sm 2xl:text-sm 3xl:p-4">
          {t('steps-quality-benchmark-toggle-tooltip')}
        </div>
        <span
          className={clsx(
            'w-6 h-6 block rounded cursor-pointer',
            showBenchmark ? 'bg-orange-600' : 'border-2 border-orange-600'
          )}
          onClick={() => setShowBenchmark(_ => !_)}
        />
      </div>

      <StepsQuality
        groupId={groupId}
        showBenchmark={showBenchmark}
        benchmarkData={benchmarkData}
        groupData={{
          preparationPhasePerformance: Number(
            planObservations[0]?.preparationPhasePerformance || 0
          ),
          communicationPhasePerformance: Number(
            planObservations[0]?.communicationPhasePerformance || 0
          ),
          executionPhasePerformance: Number(
            planObservations[0]?.executionPhasePerformance || 0
          ),
        }}
      />
    </DebriefingSection>
  );
};

export default StepsQualitySection;
