import { useMemo, useState } from 'react';
import { useObservations } from '../../store/observations';
import { useMessages } from '../../store/messages';
import DebriefingSection from '../../comps/layout/debriefing-section';
import PerformanceAdjustment from '../../comps/plots/performance-adjustment';
import PerformanceAdjustmentFilters from '../../comps/plots/performance-adjustment-filters';

interface IProps {
  sessionId: string;
  ownGroupId?: string;
}

const PerformanceAdjustmentSection = ({ sessionId, ownGroupId }: IProps) => {
  const t = useMessages(({ print }) => print);

  const [displayedLosses, setDisplayedLossed] = useState<string[]>([]);
  const [hoveredLoss, setHoveredLoss] = useState<string>();

  const { find, observations } = useObservations(({ observations, find }) => ({
    observations,
    find,
  }));

  const sessionObservations = useMemo(
    () => find(`/sessions/${sessionId}/**/groups/**/plans/executive`),
    [find, observations, sessionId]
  );

  const globalObservations = useMemo(
    () => find(`/sessions/**/**/groups/**/plans/executive`),
    [find, observations]
  );

  return (
    <DebriefingSection title={t('slide-3-title')} subTitle={t('slide-3-desc')}>
      <PerformanceAdjustmentFilters
        data={sessionObservations as any[]}
        benchmarkData={globalObservations as any[]}
        hoveredLoss={hoveredLoss}
        displayedLosses={displayedLosses}
        handleUpdate={setDisplayedLossed}
        handleHover={(loss: string) => {
          if (displayedLosses.includes(loss)) setHoveredLoss(loss);
          else setHoveredLoss(undefined);
        }}
        handleLeave={() => setHoveredLoss(undefined)}
      />
      <PerformanceAdjustment
        data={sessionObservations as any[]}
        hoveredLoss={hoveredLoss}
        displayedLosses={displayedLosses}
        ownGroupId={ownGroupId}
        handleHover={(loss: string) => {
          if (displayedLosses.includes(loss)) setHoveredLoss(loss);
          else setHoveredLoss(undefined);
        }}
        handleLeave={() => setHoveredLoss(undefined)}
      />
    </DebriefingSection>
  );
};

export default PerformanceAdjustmentSection;
