import { useMemo, useState } from 'react';
import DebriefingSection from '../layout/debriefing-section';
import DistanceDispersionMatrix from '../plots/distance-dispersion-matrix';
import { useObservations } from '../../store/observations';
import DistanceDispersionFilters from '../plots/distance-dispersion-filters';
import { scenarioLabels } from '../plots/scenario-labels';
import { useMessages } from '../../store/messages';

interface IProps {
  sessionId: string;
  groupId: string;
  handleTeamSelection: (teamId: string) => void;
}

const DistanceDispersionSection = ({
  sessionId,
  groupId,
  handleTeamSelection,
}: IProps) => {
  const t = useMessages(({ print }) => print);
  const formatNumber = useMessages(({ formatNumber }) => formatNumber);
  const [displayedScenarios, setDisplayedScenarios] = useState<string[]>([
    'neutral',
    'crisis',
  ]);

  const { find, observations } = useObservations(({ observations, find }) => ({
    observations,
    find,
  }));

  const sessionObservations = useMemo(
    () => find(`/sessions/${sessionId}/**/groups/**/plans/executive`),
    [find, observations, sessionId, groupId]
  );

  const groupObservation = sessionObservations.find(
    obs => obs.groupId === groupId
  );

  return (
    <DebriefingSection
      title={t('slide-4-title')}
      data={
        groupObservation &&
        `${scenarioLabels[
          groupObservation?.scenario
        ]?.toUpperCase()} - AcR: ${formatNumber(
          Number(groupObservation?.acceptanceRate) * 100
        )}%`
      }
      subTitle={t('slide-4-desc')}
    >
      <div className="flex flex-row space-x-8 w-full h-full">
        <div className="w-1/4 py-16 px-6 flex flex-row items-center">
          <DistanceDispersionFilters
            displayedScenarios={displayedScenarios}
            handleUpdate={v => setDisplayedScenarios(v)}
          />
        </div>

        <div className="w-3/4 flex h-full overflow-hidden">
          <div className="h-full max-h-full flex overflow-hidden aspect-square rounded-xl">
            {displayedScenarios?.length ? (
              <DistanceDispersionMatrix
                data={
                  sessionObservations.filter(({ scenario }) =>
                    displayedScenarios.includes(scenario.toString())
                  ) as any
                }
                ownGroupId={groupId}
                handleShapeClick={handleTeamSelection}
              />
            ) : (
              <p className="text-7xl font-black text-center text-slate-300/80 m-auto">
                {t('no-scenario-selected')}
              </p>
            )}
          </div>
        </div>
      </div>
    </DebriefingSection>
  );
};

export default DistanceDispersionSection;
