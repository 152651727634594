import { useEffect, useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import qs from 'query-string';
import { InView } from 'react-intersection-observer';
import { MessagesInitializer } from './comps/messages/initializer';
import DebriefingSection from './comps/layout/debriefing-section';
import TeamSelector from './comps/team-selector';
import useKeyPress from './hooks/useKeyPress';
import PresentationToolbar from './comps/presentation-toolbar';
import NavigationToolbar from './comps/navigation-toolbar';
import PerformanceAdjustmentSection from './comps/sections/performance-adjustment-section';
import ARBudgetByScenarioSection from './comps/sections/ar-budget-by-scenario-section';
import DistanceDispersionSection from './comps/sections/distance-dispersion-section';
import StepsQualitySection from './comps/sections/steps-quality-section';
import './styles.css';
import Bho from './comps/plots/bho';
import PlanAnalyzerSection from './comps/sections/plan-analyzers-section';
import buildAssetPath from './utils/buildAssetPath';
import { useObservations } from './store/observations';
import PlanAnalyzerPt2Section from './comps/sections/plan-analyzers-pt2-section';
import useDiagnostics from './hooks/useDiagnostics.hook';
import useDPI from './hooks/useDPI.hook';
import LowDPIAlert from './comps/LowDPIAlert';
import { useMessages } from './store/messages';
import useFullscreen from './hooks/useFullscreen';
import FullscreenAlert from './comps/fullscreen-alert';
import { DebriefParamsDecode } from 'ledsp-sdk';
import LandingSection from './comps/sections/landing-section';

export const App = () => {
  const isFullscreen = useFullscreen();

  const load = useObservations(({ load }) => load);

  const [sessionId, setSessionId] = useState<string>();
  const [ownTeamId, setOwnTeamId] = useState<string>();
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [fullpageApi, setFullpageApi] = useState<any>();
  const [isLandingPage, setIsLandingPage] = useState<boolean>();

  const isLeftKeyPressed = useKeyPress(37);
  const isUpKeyPressed = useKeyPress(38);
  const isRightKeyPressed = useKeyPress(39);
  const isDownKeyPressed = useKeyPress(40);

  const t = useMessages(({ print }) => print);

  const { dpi, screen } = useDPI();

  useDiagnostics();

  useEffect(() => {
    const parsed = qs.parse(location.search);
    const ledspParams =
      parsed?.ledsp?.toString() &&
      DebriefParamsDecode(parsed?.ledsp?.toString());
    const s = ledspParams?.sessionId || parsed?.session?.toString();
    const idiom = parsed?.idiom?.toString() || 'en';
    messages.changeIdiom(idiom);
    if (!s) setSessionId('CU-TEST');
    else setSessionId(s);
    setOwnTeamId(ledspParams?.teamName || parsed?.teamName?.toString());
  }, []);

  useEffect(() => {
    if (sessionId)
      load('main', `/sessions`)
        .then(() => console.log('data loaded'))
        .catch(error => console.error(error));
  }, [sessionId]);

  useEffect(() => {
    if (isDownKeyPressed || isRightKeyPressed)
      return fullpageApi.moveSectionDown();
    if (isUpKeyPressed || isLeftKeyPressed) return fullpageApi.moveSectionUp();
  }, [isLeftKeyPressed, isUpKeyPressed, isRightKeyPressed, isDownKeyPressed]);

  useEffect(() => handleTeamSelection(undefined), []);

  const handleTeamSelection = (teamId?: string) => {
    setSelectedTeamId(teamId);

    if (typeof window === 'undefined') return;

    if (teamId) fullpageApi.moveSectionDown();

    if (typeof document === 'undefined') return;

    document
      .querySelectorAll(
        '#fullpage > div.section.fp-section.active.fp-completely > div.fp-arrow.fp-controlArrow'
      )
      .forEach(arrow => ((arrow as any).style.opacity = teamId ? '0.1' : '0'));
  };

  const messages = useMessages(m => m);

  return (
    <>
      <MessagesInitializer />
      <div
        className="relative bg-gray-200 bg-cover select-none cursor-cell"
        style={{
          backgroundImage: `url("${buildAssetPath(
            'images/background_static.webp'
          )}")`,
        }}
      >
        {dpi > 1.15 &&
          screen.availWidth < 1840 &&
          screen.availWidth / dpi < 1400 && <LowDPIAlert />}

        {!isFullscreen && <FullscreenAlert isOnLandingPage={isLandingPage} />}

        {process.env.ENVIRONMENT === 'development' && (
          <button
            className="absolute z-50 p-2 m-2 font-bold rounded-md shadow-md bg-gray-200/80 backdrop-blur-sm text-slate-900 top-4 left-4"
            onClick={() =>
              messages.changeIdiom(messages.idiom === 'en' ? 'it' : 'en')
            }
            title="Change idiom (ONLY AVAILABLE IN DEVELOPMENT ENVIRONMENTS)"
          >
            {messages.idiom}
          </button>
        )}

        <ReactFullpage
          licenseKey="24B9AF4F-DBD94873-8B587720-F358572F"
          scrollingSpeed={1_400}
          css3
          loopTop={false}
          loopBottom={false}
          loopHorizontal={false}
          scrollBar={false}
          fitToSection
          render={({ fullpageApi }) => {
            if (fullpageApi) setFullpageApi(fullpageApi);

            return (
              <ReactFullpage.Wrapper>
                <LandingSection setIsLandingPage={setIsLandingPage} />

                <div className="h-screen overflow-hidden section">
                  <ARBudgetByScenarioSection
                    sessionId={sessionId}
                    ownGroupId={ownTeamId}
                  />
                </div>

                <div className="section">
                  <PerformanceAdjustmentSection
                    sessionId={sessionId}
                    ownGroupId={ownTeamId}
                  />
                </div>

                <div className="section">
                  <DistanceDispersionSection
                    sessionId={sessionId}
                    groupId={selectedTeamId || ownTeamId}
                    handleTeamSelection={(teamId: string) => {
                      // Disable team reselection in student mode.
                      if (ownTeamId) return;

                      setSelectedTeamId(teamId);
                      fullpageApi?.moveTo(6, 0);
                    }}
                  />
                </div>

                <div className="section" data-anchor="teams">
                  <InView as="div" threshold={0.4} triggerOnce={false}>
                    <TeamSelector
                      sessionId={sessionId}
                      ownTeamId={ownTeamId}
                      handleTeamSelection={handleTeamSelection}
                    />
                  </InView>
                </div>

                <div className="section" data-anchor="team-plan">
                  <PlanAnalyzerSection
                    sessionId={sessionId}
                    groupId={selectedTeamId || ownTeamId}
                  />
                </div>

                <div className="section" data-anchor="team-plan-2">
                  <PlanAnalyzerPt2Section
                    sessionId={sessionId}
                    groupId={selectedTeamId || ownTeamId}
                  />
                </div>

                <div className="section" data-anchor="team-plan-3">
                  <div className="relative flex items-center justify-center w-screen h-screen">
                    <StepsQualitySection
                      sessionId={sessionId}
                      groupId={selectedTeamId || ownTeamId}
                    />
                  </div>
                </div>

                <div className="section" data-anchor="team-plan-4">
                  <div className="relative flex items-center justify-center w-screen h-screen">
                    <DebriefingSection
                      title={t('slide-9-title')}
                      subTitle={t('slide-9-tooltip')}
                    >
                      <Bho />
                    </DebriefingSection>
                  </div>
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />

        <PresentationToolbar fullpageApi={fullpageApi} />
        <NavigationToolbar fullpageApi={fullpageApi} />
      </div>
    </>
  );
};
