import { useMemo } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ZAxis,
} from 'recharts';
import { useMessages } from '../../store/messages';
import { Shape } from './shape';

interface IData {
  isBenchmark?: boolean;
  normalizedBudgetSpent: number;
  acceptanceRate: number;
  groupId: string;
  scenario: string;
}

interface IProps {
  data: IData[];
  distinguishScenarios: boolean;
  ownGroupId?: string;
}

const ARBudget = ({ data, distinguishScenarios, ownGroupId }: IProps) => {
  const t = useMessages(({ print }) => print);
  const formatNumber = useMessages(({ formatNumber }) => formatNumber);

  const transformedData = useMemo(
    () =>
      data
        .filter(
          d => !isNaN(d.normalizedBudgetSpent) && !isNaN(d.acceptanceRate)
        )
        .map(d => ({
          isBenchmark: d.isBenchmark,
          normalizedBudgetSpent: d.normalizedBudgetSpent * 100,
          acceptanceRate: d.acceptanceRate * 100,
          groupId: d.groupId,
          scenario: distinguishScenarios ? d.scenario : '????',
          isOwnGroup: d.groupId === ownGroupId,
        })),
    [data, distinguishScenarios]
  );

  return (
    <ResponsiveContainer>
      <ScatterChart
        margin={{
          top: 80,
          right: 80,
          bottom: 80,
          left: 80,
        }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          ticks={[0, 50, 100]}
          dataKey="acceptanceRate"
          name="Acceptance rate"
          unit="%"
          domain={[0, 100]}
          label={{
            value: 'AcR',
            dy: 32,
            fontWeight: 600,
            fontSize: 12,
            opacity: 0.9,
          }}
        />
        <YAxis
          type="number"
          ticks={[0, 50, 100]}
          dataKey="normalizedBudgetSpent"
          name={t('ar-budget-budget-spent')}
          unit="%"
          domain={[0, 100]}
          label={{
            value: 'Budget',
            dx: -32,
            angle: -90,
            fontWeight: 600,
            fontSize: 12,
            opacity: 0.9,
          }}
        />
        <ZAxis type="category" dataKey="scenario" name="Scenario" />

        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={props => (
            <div className="flex flex-col p-8 space-y-4 border-b border-white shadow-xl bg-white/50 backdrop-blur-sm rounded-xl shadow-black/5">
              <p className="mb-1 text-lg font-light text-blue-600">
                {props.payload[0]?.payload?.groupId &&
                  props.payload[0]?.payload?.groupId}
              </p>

              {props.payload
                .filter(
                  ({ name }) => distinguishScenarios || name !== 'scenario'
                )
                .map(v => (
                  <span key={v.name} className="flex flex-col">
                    <span className="font-bold text-blue-900">{v.name}:</span>
                    <span className="font-light text-sky-900">
                      {isNaN(Number(v.value))
                        ? v.value === 'crisis'
                          ? 'Top-down'
                          : v.value === 'neutral'
                          ? 'Bottom-up'
                          : v.value
                        : formatNumber(Number(v.value))}
                      {v.unit}
                    </span>
                  </span>
                ))}
            </div>
          )}
        />
        <Scatter
          name="AcR / Budget"
          data={transformedData}
          shape={props => (
            <Shape {...props} distinguishScenarios={distinguishScenarios} />
          )}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default ARBudget;
