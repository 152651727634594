import { useMemo } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useMessages } from '../../store/messages';
import { Shape } from './shape';

interface IProps {
  data: {
    distanceFromCorrectPlan: number;
    dispersion: number;
    groupId: string;
    acceptanceRate: number;
    scenario: string;
    invertedEntropy: number;
  }[];
  ownGroupId: string;
  handleShapeClick?: (teamId: string) => void;
}

const DistanceDispersionMatrix = ({
  data,
  ownGroupId,
  handleShapeClick,
}: IProps) => {
  const t = useMessages(({ print }) => print);
  const formatNumber = useMessages(({ formatNumber }) => formatNumber);

  const transformedData: {
    distance: number;
    dispersion: number;
    groupId: string;
    acr: number;
    scenario: string;
    invertedEntropy: number;
  }[] = useMemo(
    () =>
      data.map(d => ({
        distance: d.distanceFromCorrectPlan,
        dispersion: d.dispersion * 100,
        groupId: d.groupId,
        acr: d.acceptanceRate * 100,
        isOwnGroup: d.groupId === ownGroupId,
        scenario: d.scenario,
        invertedEntropy: (1 - d.invertedEntropy) * 100,
      })),
    [data]
  );

  return (
    <ResponsiveContainer>
      <ScatterChart
        margin={{
          top: 80,
          right: 80,
          bottom: 80,
          left: 80,
        }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="distance"
          name={t('distance')}
          label={{
            value: t('distance'),
            dy: 32,
            fontSize: 12,
            opacity: 0.9,
            fontWeight: 600,
          }}
        />
        <YAxis
          type="number"
          ticks={[0, 50, 100]}
          dataKey="dispersion"
          name={t('dispersion')}
          label={{
            value: t('dispersion'),
            dx: -32,
            angle: -90,
            fontSize: 12,
            opacity: 0.9,
            fontWeight: 600,
          }}
          domain={[0, 100]}
          unit="%"
        />
        <Tooltip
          cursor={{ strokeDasharray: '8 8' }}
          content={props => (
            <div className="flex flex-col p-8 space-y-4 border-b border-white shadow-xl bg-white/50 backdrop-blur-sm rounded-xl shadow-black/5">
              <p className="mb-1 text-lg font-light text-blue-600">
                {props.payload[0]?.payload?.groupId &&
                  props.payload[0]?.payload?.groupId}
              </p>
              {props.payload.map(({ name, value, unit }) => (
                <span className="flex flex-col" key={name}>
                  <span className="font-bold text-blue-900">{name}:</span>
                  <span className="font-light text-sky-900">
                    {formatNumber(Number(value))}
                    {unit}
                  </span>
                </span>
              ))}

              <span className="flex flex-col">
                <span className="font-bold text-blue-900">AcR:</span>
                <span className="font-light text-sky-900">
                  {formatNumber(Number(props.payload[0]?.payload?.acr))}%
                </span>
              </span>
            </div>
          )}
        />
        <Scatter
          name="Distance / Dispersion"
          data={transformedData}
          shape={props => (
            <Shape
              {...props}
              distinguishScenarios
              handleClick={() => handleShapeClick(props.groupId)}
              label={{
                part1: t('entropy'),
                part2: formatNumber(Number(props.invertedEntropy)) + '%',
              }}
            />
          )}
          x="normalizedBudgetSpent"
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default DistanceDispersionMatrix;
