import FirestoreClient, { IFirestoreConfiguration } from './firestore-client';
import PocketbaseClient, {
  IPocketbaseConfiguration,
} from './pocketbase-client';

type Adapter = PocketbaseClient | FirestoreClient;

export default class RealtimeClient {
  adapter: Adapter;

  constructor(
    adapter: 'pocketbase' | 'firestore',
    configuration: IPocketbaseConfiguration | IFirestoreConfiguration
  ) {
    if (adapter === 'pocketbase')
      this.adapter = new PocketbaseClient(
        configuration as IPocketbaseConfiguration
      );
    else if (adapter === 'firestore')
      this.adapter = new FirestoreClient(
        configuration as IFirestoreConfiguration
      );
    else throw new Error(`Unknown adapter: \`${adapter}\`.`);
  }

  public async create(collection: string, id: string, payload: any) {
    this.adapter.create(collection, id, payload);
  }

  public async update(collection: string, id: string, payload: any) {
    this.adapter.update(collection, id, payload);
  }

  public async findOne(collection: string, id: string) {
    return this.adapter.findOne(collection, id);
  }

  public async findAll(collection: string, filters?: Record<string, any>) {
    return this.adapter.findAll(collection, filters);
  }

  public async upsert(collection: string, id: string, payload: any) {
    this.adapter.upsert(collection, id, payload);
  }

  public async delete(collection: string, id: string) {
    this.adapter.delete(collection, id);
  }

  public subscribe(
    collection: string,
    recordId: string | undefined,
    callback: (event: any) => void
  ) {
    this.adapter.subscribe(collection, recordId, callback);
  }

  public unsubscribe(collection: string, recordId: string | undefined) {
    this.adapter.unsubscribe(collection, recordId);
  }
}
