import { useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import Card from '../card';
import decimalToRoman from '../../utils/decimal-to-roman';
import useMousePosition from '../../../hooks/useMousePosition';
import { useObservations } from '../../../store/observations';
import KOTTER_STEPS from '../../../data/kotter-steps-data';
import Kotter2Filters from '../kotter-2-filters';
import { useMessages } from '../../../store/messages';


interface IProps {
  sessionId: string;
  groupId: string;
}

const NonSoCheNomeDargli = ({ sessionId, groupId }: IProps) => {
  const { x: mouseX, y: mouseY } = useMousePosition();

  const [description, setDescription] = useState<string>();
  const [displayedDescription, setDisplayedDescription] = useState<string>();
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [steps, setSteps] = useState<string[]>();

  useEffect(
    () => description && setDisplayedDescription(description),
    [description]
  );

  const { find, observations } = useObservations(({ find, observations }) => ({
    find,
    observations,
  }));

  const t = useMessages(({ print }) => print);

  const groupActionsObservations = useMemo(
    () =>
      find(
        `/sessions/${sessionId}/**/groups/${groupId}/plans/executive/actions/**`
      ),
    [find, observations, sessionId, groupId]
  );

  const idealPlan = useMemo(
    () => find(`/sessions/${sessionId}/**/groups/${groupId}/reference-plan/**`),
    [find, observations, sessionId, groupId]
  );

  if (!groupId) return <p>No team selected.</p>;

  if (!groupActionsObservations?.length) return <p>No group actions found.</p>;

  return (
    <>
      <motion.div
        className="fixed z-50 max-w-sm p-4 leading-tight text-blue-900 border-b border-white shadow-xl pointer-events-none bg-white/40 backdrop-blur-xl shadow-black/5 rounded-xl xl:max-w-md 2xl:max-w-lg"
        animate={{
          x: Math.min(
            Math.max(-(window.innerWidth / 4), mouseX - window.innerWidth / 2),
            window.innerWidth - window.innerWidth / 1.5
          ),
          y: mouseY + 24 - window.innerHeight / 2,
          opacity: description ? 1 : 0,
        }}
      >
        {displayedDescription}
      </motion.div>

      <div className="absolute bg-white py-2 px-4 rounded-xl top-[2px] left-0 right-0 mx-auto w-1/2">
        <Kotter2Filters
          activeFilters={activeFilters}
          handleUpdate={v => setActiveFilters(v)}
        />
      </div>

      <div className="relative flex flex-col justify-center p-3 3xl:p-4">
        <div className="flex flex-row flex-wrap justify-center mb-6 space-x-1 2xl:mb-8 3xl:mb-10">
          {groupActionsObservations
            .filter(({ planId }) => planId === 'executive')
            ?.sort((a, b) => Number(a.position) - Number(b.position))
            .map((groupAction, groupActionIndex) => (
              <Card
                key={groupActionIndex}
                label={decimalToRoman((KOTTER_STEPS.map(v=>v.id).indexOf(groupAction.kotterStep.toString()) + 1))}
                description={t(`${groupAction.actionId}-name`)}
                index={groupActionIndex}
                descriptionOrientation="top"
                layoutAnimationKey="non-so-che-nome-dargli-executive"
                isObscured={
                  KOTTER_STEPS.map(({ id }) => id).indexOf(
                    groupAction.kotterStep as string
                  ) === -1
                }
                handleMouseEnter={() =>
                  setDescription(t(`${groupAction.actionId}-description-rev`))
                }
                handleMouseLeave={() => setDescription(undefined)}
                handleTitleMouseEnter={() =>
                  setDescription(t(`${groupAction.actionId}-name`))
                }
                color={"#fef9c3"}
              />
            ))}
        </div>

        <div className="flex flex-row justify-center m-5 space-x-1 2xl:m-6 3xl:m-8">
          {KOTTER_STEPS.map((step, stepIndex) => (
            <span
              key={stepIndex}
              className="px-2 py-1 text-sm leading-tight text-blue-900 transition-all duration-200 ease-in-out rounded shadow xl:px-4 2xl:px-6 3xl:py-2 3xl:text-lg xl:leading-tight 2xl:leading-tight bg-yellow-100 shadow-black/5"
            >
              <span className="font-bold opacity-40">{stepIndex + 1}.</span>{' '}
              {step.label}
            </span>
          ))}
        </div>

        <div className="flex flex-row flex-wrap justify-center mt-6 space-x-1 2xl:mt-8 3xl:mt-10">
          {idealPlan
            ?.sort((a, b) => Number(a.position) - Number(b.position))
            .map((idealAction, idealActionIndex) => (
              <Card
                key={idealActionIndex}
                label={decimalToRoman((KOTTER_STEPS.map(v=>v.id).indexOf(idealAction.kotterStep.toString()) + 1))}
                description={t(`${idealAction.actionId}-name`)}
                index={idealActionIndex}
                descriptionOrientation="bottom"
                layoutAnimationKey="non-so-che-nome-dargli-ideal"
                outline={
                  activeFilters.includes('isInStrictStrategicFit') &&
                  !!idealAction.isInStrictStrategicFit &&
                  'blue'
                }
                hasBeenRevealed
                isUnused={!idealAction.isUsed}
                handleMouseEnter={() =>
                  setDescription(t(`${idealAction.actionId}-description`))
                }
                handleMouseLeave={() => setDescription(undefined)}
                handleTitleMouseEnter={() =>
                  setDescription(t(`${idealAction.actionId}-name`))
                }
                badge={
                  idealAction.isPU && activeFilters.includes('isPU')
                    ? 'PU'
                    : idealAction.isPEOU && activeFilters.includes('isPEOU')
                    ? 'PEOU'
                    : undefined
                }
                
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default NonSoCheNomeDargli;
