import { motion } from 'framer-motion';
import { useMessages } from '../store/messages';
import buildAssetPath from '../utils/buildAssetPath';

interface IProps {
  isOnLandingPage: boolean;
}

const FullscreenAlert = ({ isOnLandingPage }: IProps) => {
  const t = useMessages(({ print }) => print);

  return (
    <motion.div
      className="z-[200] absolute left-0 right-0 mx-auto"
      animate={{
        x: isOnLandingPage ? -264 : 0,
      }}
    >
      <motion.div
        animate={{
          y: [0, -4, 0, 4],
          rotateZ: [-0.4, 0, 0.4, 0],
        }}
        transition={{
          repeat: Infinity,
          duration: 4,
          repeatType: 'mirror',
          ease: 'linear',
        }}
        className="absolute z-[100] flex flex-row items-center p-1 mx-auto space-x-4 scale-75 bg-white border-2 border-blue-100 rounded-lg shadow-xl cursor-pointer 2xl:border-4 xl:scale-100 lg:p-2 xl:p-3 2xl:p-4 left-0 right-0 mx-auto top-2 hover:border-blue-400 w-72"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
             // Trigger fullscreen
             const element = document.documentElement as HTMLElement & {  
              webkitRequestFullscreen(): Promise<void>;
            };
  
          if (typeof document === 'undefined') return;
          // Set fullscreen
          if (document.fullscreenElement) document.exitFullscreen();
          else {
              if (element.requestFullscreen){element.requestFullscreen()}
              else { /* Safari */
              element.webkitRequestFullscreen();
            } 
        }
        }}
      >
        <img
          src={buildAssetPath('images/fullscreen.svg')}
          alt="Fullscreen"
          className="w-5 h-5 my-auto lg:w-6 lg:h-6 xl:w-7 xl:h-7 2xl:w-8 2xl:h-8"
        />
        <img
          src={buildAssetPath('images/fullscreen.svg')}
          alt="Fullscreen"
          className="absolute top-0 bottom-0 w-5 h-5 my-auto -left-2 opacity-20 lg:w-6 lg:h-6 xl:w-7 xl:h-7 2xl:w-8 2xl:h-8 animate-ping 2xl:left-1"
        />
        <p className="my-auto font-semibold leading-tight xl:leading-tight 2xl:leading-tight text-xxs xl:text-xs 2xl:text-sm text-slate-600">
          {t('fullscreen-alert')}
        </p>
      </motion.div>
    </motion.div>
  );
};

export default FullscreenAlert;
