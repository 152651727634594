import create, { GetState, SetState } from 'zustand';
import * as Logger from './factories/logger';
import * as Observations from './factories/observations';
import { observations } from './remote/observations';
import RealtimeClient from '../../utils/realtime-client';

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Logger.create(set, get),
  ...Observations.create(
    observations(
      new RealtimeClient(
        process.env.REALTIME_STORE_ENGINE as ConstructorParameters<
          typeof RealtimeClient
        >[0],
        JSON.parse(
          process.env.REALTIME_STORE_CONFIGURATION
        ) as ConstructorParameters<typeof RealtimeClient>[1]
      )
    )
  )(set, get),
});

export const useObservations = create(createRootSlice);
