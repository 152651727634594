export default Object.freeze([
  {
    id: 'azione-contesto',
    name: 'Analizzare il contesto socio-economico',
    description:
      "Raccogliere ulteriori informazioni (da fonti interne ed esterne all'azienda) per redigere rapidamente un report con un outlook del contesto socio-economico di riferimento",
    strategicFit: 'neutral',
    kotterStep: 'urgency',
  },
  {
    id: 'azione-motivo',
    name: 'Comprendere perchè cambiare',
    description:
      'Organizzare un meeting con un altro esperto di progetti di cambiamento organizzativo "complessi" con il cui aiuto riuscire a mettere in luce, in breve tempo, le ragioni che richiedono un cambiamento',
    strategicFit: 'neutral',
    kotterStep: 'urgency',
  },
  {
    id: 'azione-mappatura',
    name: 'Mappare la percezione sulla smart organization',
    description:
      'Analizzare il livello di percezione dei collaboratori dell\'azienda come "una smart organization',
    strategicFit: 'bottom-up',
    kotterStep: 'urgency',
  },
  {
    id: 'azione-webinar',
    name: 'Incontrare il CEO',
    description:
      'Il CEO si prepara ad incontrare, tramite un webinar, tutti i collaboratori per illustrare loro le ragioni sottostanti la necessità di far partire quanto prima un progetto di riorganizzazione aziendale',
    strategicFit: 'top-down',
    kotterStep: 'urgency',
  },
  {
    id: 'azione-decisione',
    name: 'Decidere sul futuro',
    description:
      'Il top management incontra due consiglieri interni (due collaboratori di lunga esperienza in azienda e con elevato standing riconosciuto da tutti) e due consiglieri esterni (due consulenti la cui preparazione ed elevata reputazione sono riconosciute da tutti quanti in azienda) per discutere con loro la situazione e i possibili scenari futuri',
    strategicFit: 'top-down',
    kotterStep: 'coalition',
  },
  {
    id: 'azione-popolazione',
    name: 'Analizzare la popolazione aziendale',
    description:
      "Analizzare i profili di tutti i collaboratori dell'azienda per individuare l'orientamento all'innovazione e la propensione al rischio. L'obiettivo è poi selezionarne alcuni da coinvolgere nella task force di progetto",
    strategicFit: 'bottom-up',
    kotterStep: 'coalition',
  },
  {
    id: 'azione-team',
    name: 'Comporre team specializzati',
    description:
      "Creare uno o più team specializzati, ognuno dei quali contribuirà a tutte le fasi del progetto di evoluzione organizzativa, sotto la guida dell'unica regia generale dell'intero progetto.",
    strategicFit: 'neutral',
    kotterStep: 'coalition',
  },
  {
    id: 'azione-esperto',
    name: 'Coinvolgere un esperto',
    description:
      'Si chiede ad un esperto (tra i "top 50 global leaders in change management") di entrare a far parte del team di progetto e di partecipare solo agli incontri "milestone".',
    strategicFit: 'neutral',
    kotterStep: 'coalition',
  },
  {
    id: 'azione-passato',
    name: 'Fare tesoro del passato',
    description:
      'Fare leva sulle esperienze pregresse di gestione di change management per elaborare la situazione futura e il percorso per il suo raggiungimento',
    strategicFit: 'neutral',
    kotterStep: 'vision-and-strategy',
  },
  {
    id: 'azione-strategia',
    name: 'Pianificare una strategia',
    description:
      "Fare leva su dati e report (da fonti interne/esterne affidabili) per elaborare l'indirizzo strategico e il piano dei lavori di breve e medio-lungo termine",
    strategicFit: 'neutral',
    kotterStep: 'vision-and-strategy',
  },
  {
    id: 'azione-casi',
    name: 'Elaborare dei business case',
    description:
      'Elaborare, tramite focus group, dei business case che illustrino la "nuova organizzazione smart" e, sulla base di questi, redigere il piano di change management',
    strategicFit: 'bottom-up',
    kotterStep: 'vision-and-strategy',
  },
  {
    id: 'azione-piano',
    name: 'Definire il programma di cambiamento',
    description:
      'Il top management definisce il piano di change management, ivi comprese le aree di intervento, le fasi in cui sarà articolato, gli indicatori e le modalità di monitoraggio continuo',
    strategicFit: 'top-down',
    kotterStep: 'vision-and-strategy',
  },
  {
    id: 'azione-esempi',
    name: 'Diffondere esempi virtuosi',
    description:
      'Diffondere esempi virtuosi di smart organization (walk the talk), eventualmente personalizzati per ciascun gruppo di stakeholder aziendali',
    strategicFit: 'neutral',
    kotterStep: 'communicate',
  },
  {
    id: 'azione-roadshow',
    name: 'Organizzare un roadshow',
    description:
      'Organizzare un roadshow, coinvolgendo i principali stakeholder, per presentare il progetto di change management, ivi compresi i principali risultati attesi, le tappe intermedie, gli indicatori e le modalità di monitoraggio continuo',
    strategicFit: 'neutral',
    kotterStep: 'communicate',
  },
  {
    id: 'azione-manager',
    name: 'Presentare il piano ai manager',
    description:
      'Il top management organizza un incontro con i suoi diretti riporti per presentare loro, nel modo più chiaro possibile, la necessità ed il piano di change management nonchè le modalità per la sua attuazione',
    strategicFit: 'top-down',
    kotterStep: 'communicate',
  },
  {
    id: 'azione-benchmarking',
    name: 'Condividere il benchmarking',
    description:
      'Far circolare tra i dipendenti un report di benchmarking che presenti le condizioni, le principali caratteristiche e la performance delle organizzazioni "smart" prese a riferimento come virtuose',
    strategicFit: 'bottom-up',
    kotterStep: 'communicate',
  },
  {
    id: 'azione-allineamento',
    name: 'Allineare il team rispetto al piano',
    description:
      'Il team di progetto si confronta costantemente per allinearsi sulla pianificazione ed eventualmente rivedere il piano alla luce dei risultati e i feedback raccolti in itinere',
    strategicFit: 'neutral',
    kotterStep: 'empower-action',
  },
  {
    id: 'azione-comportamenti',
    name: 'Indicare i comportamenti attesi',
    description:
      "Il top management indica, in modo chiaro e puntuale, i comportamenti attesi dai propri collaboratori sia durante l'intera fase di attuazione del percorso di evoluzione organizzativa sia dopo",
    strategicFit: 'top-down',
    kotterStep: 'empower-action',
  },
  {
    id: 'azione-pilota',
    name: "Promuovere un'iniziativa pilota",
    description:
      'Focalizzare le attività di tipo operativo del piano di change su una specifica unità organizzativa (es. funzione o dipartimento) che agisca da "pilota" e poi gradualmente implementarle sulle altre',
    strategicFit: 'bottom-up',
    kotterStep: 'empower-action',
  },
  {
    id: 'azione-training',
    name: 'Organizzare sessioni di training',
    description:
      "Organizzare sessioni di formazione e training on-the-job per illustrare le policy e gli strumenti a disposizione per aumentare l'efficacia come smart worker",
    strategicFit: 'neutral',
    kotterStep: 'empower-action',
  },
  {
    id: 'azione-visibilita',
    name: "Dare visibilità dell'avanzamento",
    description:
      "Monitorare l'avanzamento del progetto e organizzare focus-group periodici per darne continua visibilità ai principali stakeholder",
    strategicFit: 'neutral',
    kotterStep: 'short-term-wins',
  },
  {
    id: 'azione-risultati',
    name: 'Condividere i risultati',
    description:
      'Elaborare e diffondere un report settimanale che metta in luce i risultati ottenuti, anche se piccoli, verso il percorso per costruire una smart organization',
    strategicFit: 'top-down',
    kotterStep: 'short-term-wins',
  },
  {
    id: 'azione-testimonianze',
    name: 'Raccogliere testimonianze positive',
    description:
      "Raccogliere dei video realizzati dai dipendenti che li ritraggano in simpatiche scene di vita quotidiana in smart working da condividere con tutta l'organizzazione",
    strategicFit: 'bottom-up',
    kotterStep: 'short-term-wins',
  },
  {
    id: 'azione-infografiche',
    name: 'Realizzare infografiche sui benefici',
    description:
      "Realizzare e condividere delle infografiche che illustrino chiaramente e sinteticamente i benefici man mano ottenuti per l'intera azienda",
    strategicFit: 'neutral',
    kotterStep: 'short-term-wins',
  },
  {
    id: 'azione-monitoraggio',
    name: 'Monitorare la performance',
    description:
      'Tramite alcune instant survey strutturate, monitorare la performance corrente rispetto a quella attesa, da discutere con i vari interlocutori',
    strategicFit: 'neutral',
    kotterStep: 'consolidate-gains',
  },
  {
    id: 'azione-feedback',
    name: 'Restituire dei feedback',
    description:
      "Incaricare un membro del team di progetto di formulare e restituire dei feedback rispetto all'eventuale distanza tra i risultati ottenuti e quelli attesi",
    strategicFit: 'neutral',
    kotterStep: 'consolidate-gains',
  },
  {
    id: 'azione-workshop',
    name: 'Organizzare una serie di workshop',
    description:
      'Organizzare una serie di workshop per continuare ad illustrare le implicazioni organizzative ed operative tipiche della smart organization (tra cui, ad esempio, come stanno evolvendo i ruoli, i nuovi comportamenti attesi)',
    strategicFit: 'bottom-up',
    kotterStep: 'consolidate-gains',
  },
  {
    id: 'azione-agire',
    name: 'Identificare delle aree di intervento',
    description:
      'Identificare delle aree di intervento su cui agire in termini di "comportamenti e azioni" messi in atto rispetto a "comportamenti e azioni" ideali di una smart organization',
    strategicFit: 'top-down',
    kotterStep: 'consolidate-gains',
  },
  {
    id: 'azione-risorse',
    name: 'Ribadire le risorse a supporto',
    description:
      'Ricordare, in occasione dei management meeting formali già previsti per ogni trimestre, le risorse ed i supporti a disposizione di tutta la popolazione aziendale per continuare nella direzione di costruire una smart organization',
    strategicFit: 'neutral',
    kotterStep: 'anchor-change',
  },
  {
    id: 'azione-riconoscimento',
    name: 'Istituire un nuovo riconoscimento',
    description:
      'Istituire un nuovo riconoscimento formale, su base annuale, delle best practice e comportamenti degli smart worker',
    strategicFit: 'top-down',
    kotterStep: 'anchor-change',
  },
  {
    id: 'azione-onboarding',
    name: 'Aggiornare le iniziative di onboarding',
    description:
      'Integrare nelle iniziative di onboarding dei nuovi collaboratori le modalità di lavoro della smart organization',
    strategicFit: 'bottom-up',
    kotterStep: 'anchor-change',
  },
  {
    id: 'azione-formalizzare',
    name: 'Formalizzare nuovi strumenti e pratiche',
    description:
      "Rivedere (ove opportuno) e formalizzare l'introduzione di nuovi strumenti e pratiche per rendere la smart organization ancora più efficace",
    strategicFit: 'neutral',
    kotterStep: 'anchor-change',
  },
]);
