import { useState } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import buildAssetPath from '../utils/buildAssetPath';
import { useMessages } from '../store/messages';

interface IProps {
  fullpageApi: any;
}

const PresentationToolbar = ({ fullpageApi }: IProps) => {
  const t = useMessages(({ print }) => print);
  const [isMouseOver, setMouseOver] = useState<boolean>(false);

  return (
    <>
      <div
        className="bg-red-500 w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 flex flex-row justify-center space-x-8 rounded-xl p-2 shadow-xl border-b border-blue-100 fixed bottom-6 left-0 right-0 mx-auto duration-200 ease-in-out transition-all cursor-default backdrop-blur-sm z-40 opacity-0"
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      />

      <motion.div
        className="w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 flex flex-row justify-center space-x-8 rounded-xl p-8 bg-blue-100/50 shadow-xl border-b border-blue-100 fixed bottom-4 left-0 right-0 mx-auto duration-200 ease-in-out transition-all cursor-default backdrop-blur-sm z-50"
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        animate={{
          y: isMouseOver ? 0 : 100,
          transition: { duration: 0.2, type: 'spring', bounce: 0.6 },
          opacity: isMouseOver ? 1 : 0.9,
        }}
      >
        <p
          className={clsx(
            'top-1 font-medium left-0 right-0 text-center mx-auto text-white uppercase text-xs absolute transition-all duration-200 ease-in-out',
            isMouseOver ? 'opacity-0' : 'opacity-100'
          )}
        >
          {t('toolbar')}
        </p>

        <img
          src={buildAssetPath('images/page-button.png')}
          alt="Previous"
          className="w-10 -rotate-90 cursor-pointer hover:scale-105 hover:mix-blend-screen active:scale-95 active:mix-blend-darken transition-all duration-100 ease-in-out"
          onClick={() => fullpageApi?.moveSectionUp()}
        />
        <img
          src={buildAssetPath('images/page-button.png')}
          alt="Next"
          className="w-10 rotate-90 cursor-pointer hover:scale-105 hover:mix-blend-screen active:scale-95 active:mix-blend-darken transition-all duration-100 ease-in-out"
          onClick={() => fullpageApi?.moveSectionDown()}
        />
        <img
          src={buildAssetPath('images/teams-menu.png')}
          alt="Teams"
          className="w-10 cursor-pointer hover:scale-105 hover:mix-blend-screen active:scale-95 active:mix-blend-darken transition-all duration-100 ease-in-out"
          onClick={() => fullpageApi?.moveTo(5)}
        />
        <img
          src={buildAssetPath('images/start.png')}
          alt="Start"
          className="w-10 cursor-pointer hover:scale-105 hover:mix-blend-screen active:scale-95 active:mix-blend-darken transition-all duration-100 ease-in-out"
          onClick={() => fullpageApi?.moveTo(1)}
        />
      </motion.div>
    </>
  );
};

export default PresentationToolbar;
