import { useMemo, useState } from 'react';
import Countup from 'react-countup';
import DebriefingSection from '../layout/debriefing-section';
import ARBudget from '../plots/ar-budget';
import { useObservations } from '../../store/observations';
import ARBudgetFilters from '../plots/ar-budget-filters';
import { useMessages } from '../../store/messages';

interface IProps {
  sessionId: string;
  ownGroupId?: string;
}

const ARBudgetByScenarioSection = ({ sessionId, ownGroupId }: IProps) => {
  const maxBudget: number = 100;
  const t = useMessages(({ print }) => print);

  const [areBenchmarksVisible, setBenchmarksVisible] = useState<boolean>(false);

  const [displayedScenarios, setDisplayedScenarios] = useState<string[]>([
    'neutral',
    'crisis',
  ]);
  const [scenariosDistinguished, distinguishScenarios] = useState(false);

  const { find, observations } = useObservations(({ observations, find }) => ({
    observations,
    find,
  }));

  const sessionObservations = useMemo(
    () => find(`/sessions/${sessionId}/**/groups/**/plans/executive`),
    [find, observations, sessionId]
  );

  const getAverageAcceptanceRate = (byScenario?: string) =>
    sessionObservations
      .filter(({ scenario }) => (byScenario ? scenario === byScenario : true))
      .reduce((total, planData) => total + Number(planData.acceptanceRate), 0) /
    sessionObservations.filter(({ scenario }) =>
      byScenario ? scenario === byScenario : true
    ).length;

  const getAverageBudgetSpent = (byScenario?: string) =>
    sessionObservations
      .filter(({ scenario }) => (byScenario ? scenario === byScenario : true))
      .reduce(
        (total, planData) => total + Number(planData.normalizedBudgetSpent),
        0
      ) /
    sessionObservations.filter(({ scenario }) =>
      byScenario ? scenario === byScenario : true
    ).length;

  const filteredSessionObservations = useMemo(
    () =>
      sessionObservations.filter(observation =>
        !displayedScenarios?.length
          ? false
          : displayedScenarios.includes('all')
          ? true
          : displayedScenarios.some(ds => ds === observation.scenario)
      ),
    [sessionObservations, displayedScenarios]
  );

  const globalObservations = useMemo(
    () => find('/sessions/**/**/groups/**/plans/executive'),
    [find, observations]
  );

  const toBenchmark = (acc, current, index, all) => ({
    ...acc,
    normalizedBudgetSpent:
      (Number(acc.normalizedBudgetSpent) +
        Number(current.normalizedBudgetSpent)) /
      all.length,
    acceptanceRate:
      (Number(acc.acceptanceRate) + Number(current.acceptanceRate)) /
      all.length,
  });

  const crisisBenchmark = globalObservations
    .filter(({ scenario }) => scenario === 'crisis')
    .reduce(toBenchmark, {
      objectId: 'crisis-benchmark',
      isBenchmark: true,
      scenario: 'crisis',
      groupId: 'Top-down',
      normalizedBudgetSpent: 0,
      acceptanceRate: 0,
    });

  const neutralBenchmark = globalObservations
    .filter(({ scenario }) => scenario === 'neutral')
    .reduce(toBenchmark, {
      objectId: 'neutral-benchmark',
      isBenchmark: true,
      scenario: 'neutral',
      groupId: 'Bottom-up',
      normalizedBudgetSpent: 0,
      acceptanceRate: 0,
    });

  const globalBenchmark = globalObservations.reduce(toBenchmark, {
    objectId: 'global-benchmark',
    isBenchmark: true,
    scenario: 'all',
    groupId: 'Benchmark',
    normalizedBudgetSpent: 0,
    acceptanceRate: 0,
  });

  const data = [
    [
      filteredSessionObservations.filter(
        ({ scenario }) => scenario === 'crisis'
      ),
      () => displayedScenarios.includes('crisis'),
    ],
    [
      filteredSessionObservations.filter(
        ({ scenario }) => scenario === 'neutral'
      ),
      () => displayedScenarios.includes('neutral'),
    ],
    [
      crisisBenchmark,
      () =>
        scenariosDistinguished &&
        areBenchmarksVisible &&
        displayedScenarios.includes('crisis'),
    ],
    [
      neutralBenchmark,
      () =>
        scenariosDistinguished &&
        areBenchmarksVisible &&
        displayedScenarios.includes('neutral'),
    ],
    [globalBenchmark, () => !scenariosDistinguished && areBenchmarksVisible],
  ]
    .filter(([list, filterFunction]) => filterFunction())
    .map(v => v[0])
    .reduce((acc, el) => acc.concat(el), []);

  return (
    <DebriefingSection title={t('slide-2-title')} subTitle={t('slide-2-desc')}>
      <div className="flex flex-row w-full h-full">
        <div className="flex flex-col w-1/6 py-8 pl-2 pr-8 space-y-4 2xl:space-y-8">
          <ARBudgetFilters
            benchmarksShown={areBenchmarksVisible}
            toggleBenchmarks={() => setBenchmarksVisible(_ => !_)}
            displayedScenarios={displayedScenarios}
            scenariosDistinguished={scenariosDistinguished}
            handleUpdate={v => setDisplayedScenarios(v)}
            handleDistinguishScenarios={() =>
              distinguishScenarios(!scenariosDistinguished)
            }
          />
        </div>

        <div className="w-3/6 h-full max-h-full overflow-hidden">
          <div className="h-full max-h-full overflow-hidden aspect-square rounded-xl">
            <ARBudget
              data={data}
              distinguishScenarios={scenariosDistinguished}
              ownGroupId={ownGroupId}
            />
          </div>
        </div>

        <div className="flex flex-col w-2/6 py-8 pl-2 pr-8 space-y-4 2xl:space-y-8 3xl:space-y-10">
          <span className="flex flex-col">
            <p className="mb-1 text-sm font-semibold text-gray-600 uppercase">
              {t('all-scenarios')}
            </p>
            <span className="flex flex-row w-full space-x-16">
              <span className="flex flex-col space-y-1">
                <span className="text-xl font-bold text-sky-900">
                  {t('average-acr')}
                </span>
                <span
                  className="text-4xl font-black 2xl:text-5xl opacity-80"
                  style={{ color: '#6A5ACD' }}
                >
                  <Countup
                    enableScrollSpy
                    end={getAverageAcceptanceRate() * 100}
                    duration={1}
                  />
                  %
                </span>
              </span>

              <span className="flex flex-col space-y-1">
                <span className="text-xl font-bold text-sky-900">
                  {t('average-budget-spent')}
                </span>
                <span
                  className="text-4xl font-black 2xl:text-5xl opacity-80"
                  style={{ color: '#6A5ACD' }}
                >
                  <Countup
                    enableScrollSpy
                    end={getAverageBudgetSpent() * maxBudget}
                    duration={1}
                  />
                  %
                </span>
              </span>
            </span>
          </span>

          {scenariosDistinguished && displayedScenarios.includes('neutral') && (
            <span className="flex flex-col">
              <p className="mb-1 text-sm font-semibold text-gray-600 uppercase">
                Scenario "Bottom-up"
              </p>
              <span className="flex flex-row w-full space-x-16">
                <span className="flex flex-col space-y-1">
                  <span className="text-xl font-bold text-sky-900">
                    {t('average-acr')}
                  </span>
                  <span className="text-4xl font-black text-blue-600 2xl:text-5xl opacity-80">
                    <Countup
                      enableScrollSpy
                      end={getAverageAcceptanceRate('neutral') * 100}
                      duration={1}
                    />
                    %
                  </span>
                </span>

                <span className="flex flex-col space-y-1">
                  <span className="text-xl font-bold text-sky-900">
                    {t('average-budget-spent')}
                  </span>
                  <span className="text-4xl font-black text-blue-600 2xl:text-5xl opacity-80">
                    <Countup
                      enableScrollSpy
                      end={getAverageBudgetSpent('neutral') * maxBudget}
                      duration={1}
                    />
                    %
                  </span>
                </span>
              </span>
            </span>
          )}

          {scenariosDistinguished && displayedScenarios.includes('crisis') && (
            <span className="flex flex-col">
              <p className="mb-1 text-sm font-semibold text-gray-600 uppercase">
                Scenario "Top-down"
              </p>
              <span className="flex flex-row w-full space-x-16">
                <span className="flex flex-col space-y-1">
                  <span className="text-xl font-bold text-sky-900">
                    {t('average-acr')}
                  </span>
                  <span className="text-4xl font-black text-purple-600 2xl:text-5xl opacity-80">
                    <Countup
                      enableScrollSpy
                      end={getAverageAcceptanceRate('crisis') * 100}
                      duration={1}
                    />
                    %
                  </span>
                </span>

                <span className="flex flex-col space-y-1">
                  <span className="text-xl font-bold text-sky-900">
                    {t('average-budget-spent')}
                  </span>
                  <span className="text-4xl font-black text-purple-600 2xl:text-5xl opacity-80">
                    <Countup
                      enableScrollSpy
                      end={getAverageBudgetSpent('crisis') * maxBudget}
                      duration={1}
                    />
                    %
                  </span>
                </span>
              </span>
            </span>
          )}
        </div>
      </div>
    </DebriefingSection>
  );
};

export default ARBudgetByScenarioSection;
