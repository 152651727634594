import { motion } from 'framer-motion';
import KOTTER_STEPS from '../../data/kotter-steps-data';
import { useMessages } from '../../store/messages';
import buildAssetPath from '../../utils/buildAssetPath';
import clsx from 'clsx';

interface IData {
  preparationPhasePerformance: number;
  communicationPhasePerformance: number;
  executionPhasePerformance: number;
}

interface IProps {
  groupId: string;
  groupData: IData;
  benchmarkData: IData;
  showBenchmark: boolean;
}

const StepsQuality = ({
  groupId,
  groupData,
  benchmarkData,
  showBenchmark,
}: IProps) => {
  const t = useMessages(({ print }) => print);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <p className="my-4 text-xl font-bold text-blue-900">Team: {groupId}</p>
      <div className="flex flex-col w-11/12 space-y-8">
        <div className="grid w-full h-24 grid-cols-8 grid-rows-1 gap-1 rounded">
          {KOTTER_STEPS.map((step, index) => (
            <div
              key={index}
              className={clsx("flex items-center justify-center w-full text-xs font-semibold text-center rounded 3xl:text-sm leading-tight backdrop-blur-sm text-slate-900",
              index <= 2
              ? 'bg-blue-300'
              :index === 3
              ? 'bg-slate-300'
              : 'bg-white')}
            >
              {index + 1}. {step.label}
            </div>
          ))}
        </div>

        <div className="grid w-full h-24 grid-cols-8 grid-rows-1 gap-1 rounded">
          <div className="flex flex-col items-center justify-center col-span-3 rounded bg-white/60 backdrop-blur-sm">
            <span className="relative flex flex-row space-x-2 text-xs font-semibold text-slate-900 3xl:text-sm">
              <p>Preparation</p>
              <img
                src={buildAssetPath('images/info-small.svg')}
                alt="Info"
                className="w-4 h-4 peer cursor-help"
              />
              <span className="absolute z-50 w-64 p-4 text-base font-normal leading-tight text-white transition-all duration-200 ease-in-out border-b-2 border-black rounded shadow-xl opacity-0 pointer-events-none 3xl:text-lg 3xl:leading-tight -right-4 -top-40 bg-black/80 backdrop-blur-sm peer-hover:opacity-100">
                {t('steps-quality-preparation-tooltip')}
              </span>
            </span>
            <span className="text-2xl font-black 3xl:text-3xl text-blue-900/60">
              {(groupData?.preparationPhasePerformance * 100).toFixed(0)}%
            </span>
          </div>
          <div className="flex flex-col items-center justify-center col-span-1 overflow-visible rounded bg-white/60 backdrop-blur-sm">
            <span className="relative flex flex-row space-x-2 text-xs font-semibold text-slate-900 3xl:text-sm">
              <p>Communication</p>
              <img
                src={buildAssetPath('images/info-small.svg')}
                alt="Info"
                className="w-4 h-4 peer cursor-help"
              />
              <span className="absolute z-50 w-64 p-4 text-base font-normal leading-tight text-white transition-all duration-200 ease-in-out border-b-2 border-black rounded shadow-xl opacity-0 pointer-events-none 3xl:text-lg 3xl:leading-tight -right-4 -top-40 bg-black/80 backdrop-blur-sm peer-hover:opacity-100">
                {t('steps-quality-communication-tooltip')}
              </span>
            </span>
            <span className="text-2xl font-black 3xl:text-3xl text-blue-900/60">
              {(groupData?.communicationPhasePerformance * 100).toFixed(0)}%
            </span>
          </div>
          <div className="flex flex-col items-center justify-center col-span-4 rounded bg-white/60 backdrop-blur-sm">
            <span className="relative flex flex-row space-x-2 text-xs font-semibold text-slate-900 3xl:text-sm">
              <p>Execution</p>
              <img
                src={buildAssetPath('images/info-small.svg')}
                alt="Info"
                className="w-4 h-4 peer cursor-help"
              />
              <span className="absolute z-50 w-64 p-4 text-base font-normal leading-tight text-white transition-all duration-200 ease-in-out border-b-2 border-black rounded shadow-xl opacity-0 pointer-events-none 3xl:text-lg 3xl:leading-tight -right-4 -top-52 bg-black/80 backdrop-blur-sm peer-hover:opacity-100">
                {t('steps-quality-execution-tooltip')}
              </span>
            </span>
            <span className="text-2xl font-black 3xl:text-3xl text-blue-900/60">
              {(groupData?.executionPhasePerformance * 100).toFixed(0)}%
            </span>
          </div>
        </div>

        <motion.div
          className="grid w-full h-24 grid-cols-8 grid-rows-1 gap-1 rounded"
          animate={{
            opacity: showBenchmark ? 1 : 0,
            y: showBenchmark ? 0 : 100,
            filter: showBenchmark ? 'blur(0px)' : 'blur(8px)',
          }}
          transition={{
            duration: 0.5,
          }}
        >
          <div className="flex flex-col items-center justify-center col-span-3 rounded bg-white/60 backdrop-blur-sm">
            <span className="relative flex flex-row space-x-2 text-xs font-semibold text-orange-900 3xl:text-sm">
              <p>Preparation</p>
              <img
                src={buildAssetPath('images/info-small.svg')}
                alt="Info"
                className="w-4 h-4 peer cursor-help"
              />
              <span className="absolute z-50 w-64 p-4 text-base font-normal leading-tight text-white transition-all duration-200 ease-in-out rounded opacity-0 pointer-events-none 3xl:text-lg 3xl:leading-tight -right-4 top-1 bg-black/80 backdrop-blur-lg peer-hover:opacity-100">
                {t('steps-quality-preparation-tooltip')}
              </span>
            </span>
            <span className="text-2xl font-black 3xl:text-3xl text-orange-900/40">
              {(benchmarkData?.preparationPhasePerformance * 100).toFixed(0)}%
            </span>
          </div>
          <div className="flex flex-col items-center justify-center col-span-1 overflow-visible rounded bg-white/60 backdrop-blur-sm">
            <span className="relative flex flex-row space-x-2 text-xs font-semibold text-orange-900 3xl:text-sm">
              <p>Communication</p>
              <img
                src={buildAssetPath('images/info-small.svg')}
                alt="Info"
                className="w-4 h-4 peer cursor-help"
              />
              <span className="absolute z-50 w-64 p-4 text-base font-normal leading-tight text-white transition-all duration-200 ease-in-out rounded opacity-0 pointer-events-none 3xl:text-lg 3xl:leading-tight -right-4 top-1 bg-black/80 backdrop-blur-lg peer-hover:opacity-100">
                {t('steps-quality-communication-tooltip')}
              </span>
            </span>
            <span className="text-2xl font-black 3xl:text-3xl text-orange-900/40">
              {(benchmarkData?.communicationPhasePerformance * 100).toFixed(0)}%
            </span>
          </div>
          <div className="flex flex-col items-center justify-center col-span-4 rounded bg-white/60 backdrop-blur-sm">
            <span className="relative flex flex-row space-x-2 text-xs font-semibold text-orange-900 3xl:text-sm">
              <p>Execution</p>
              <img
                src={buildAssetPath('images/info-small.svg')}
                alt="Info"
                className="w-4 h-4 peer cursor-help"
              />
              <span className="absolute z-50 w-64 p-4 text-base font-normal leading-tight text-white transition-all duration-200 ease-in-out rounded opacity-0 pointer-events-none 3xl:text-lg 3xl:leading-tight -right-4 top-1 bg-black/80 backdrop-blur-lg peer-hover:opacity-100">
                {t('steps-quality-execution-tooltip')}
              </span>
            </span>
            <span className="text-2xl font-black 3xl:text-3xl text-orange-900/40">
              {(benchmarkData?.executionPhasePerformance * 100).toFixed(0)}%
            </span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default StepsQuality;
