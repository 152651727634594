import { useEffect, useState } from 'react';

export default function useDPI() {
  const [dpi, setDpi] = useState<number>(1);
  const [screenInfo, setScreenInfo] = useState<Screen>();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    setDpi(window.devicePixelRatio || 1);
    setScreenInfo(window.screen);

    function handleResize() {
      setDpi(window.devicePixelRatio || 1);
      setScreenInfo(window.screen);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { dpi, screen: screenInfo };
}
