import { IObservationRegistryApi } from './api';
import RealtimeClient from '../../../utils/realtime-client';

export const observations = (db: RealtimeClient): IObservationRegistryApi => ({
  async register(directoryId, observations) {
    console.log('directoryId, observations', directoryId, observations);

    for (const observation of observations)
      await db.upsert('observations', observation.objectId, {
        observation,
        ...observation,
        directoryId,
      });
  },

  // TODO: Check if directoryId is needed.
  async find(directoryId, { objectId }) {
    console.log('find observations', directoryId, objectId);

    const results = await db.findAll('observations', {
      startingWith: ['objectId', objectId],
    });

    return (results as any[])?.map(result => result.observation);
  },
});
