import { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useObservations } from '../store/observations';
import { useMessages } from '../store/messages';
import buildAssetPath from '../utils/buildAssetPath';
import clsx from 'clsx';



const TeamSelector = ({ sessionId, ownTeamId, handleTeamSelection }) => {
  const t = useMessages(({ print }) => print);

  const [teamSelected, setTeam] = useState <string | number>()

  const { find, observations } = useObservations(({ find, observations }) => ({
    find,
    observations,
  }));

  const groups = useMemo(
    () => [
      ...new Set(
        find(`/sessions/${sessionId}/**/groups/**`)
          ?.map(({ groupId }) => groupId)
          .filter(Boolean)
          .filter(groupId => (ownTeamId ? groupId === ownTeamId : true))
          .sort((a, b) => a.toString().localeCompare(b.toString()))
      ),
    ],
    [find, observations, sessionId, ownTeamId]
  );

  return (
    <motion.div className="w-screen h-screen">
      <div className="h-[10vh] px-8 lg:px-10 xl:px-12 2xl:px-16 flex items-center ">
          <img	src={buildAssetPath('images/logo_bocconi.png')}
      alt="Logo Bocconi"
      className="my-auto mr-auto h-1/2 _nodrag"
            />
      </div>

        <div className='flex flex-col items-center justify-center h-[90vh] gap-10 px-24 py-20 xl:gap-12 2xl:gap-16 3xl:gap-20 2xl:px-28 3xl:px-32'>
          <h2 className="text-4xl font-black text-blue-100 xl:text-5xl 2xl:text-6xl 3xl:text-7xl">
            {t('playing-teams')}
          </h2>

          <div className="flex flex-row flex-wrap w-full gap-6 p-2 justify-center">
            {groups.map((team, index) => (
              <motion.div
                key={team}
                className= {clsx("p-10 text-2xl font-bold text-blue-100/90 text-center flex items-center justify-center w-1/5 rounded-xl bg-gradient-to-tr from-blue-200/10 to-blue-100/10 cursor-pointer backdrop-blur-md shadow-xl shadow-black/[0.02] hover:shadow-black/[0.04] active:shadow-black/[0.014] active:shadow-inner active:text-blue-200/90",team===teamSelected?"border-2 border-white":"border-b border-b-blue-100/40")}
                initial={{ scale: 0.9 }}
                animate={{ scale: 1, transition: { delay: 0 } }}
                transition={{ delay: 0.4 + 0.2 * index }}
                whileHover={{ scale: 1.05, transition: { delay: 0 } }}
                whileTap={{
                  scale: 0.99,
                  transition: { duration: 0.1, delay: 0 },
                }}
                onClick={() => {handleTeamSelection(team), setTeam(team)}}
              >
                Team {team}
              </motion.div>
            ))}
          </div>
        </div> 
    </motion.div>
  );
};

export default TeamSelector;
