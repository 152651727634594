"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const query_string_1 = require("query-string");
const ledsp_encoding_1 = require("./ledsp-encoding");
function useLedspParams() {
    var _a, _b, _c, _d;
    const parsed = query_string_1.default.parse(location.search);
    const ledsp = (_a = parsed === null || parsed === void 0 ? void 0 : parsed.ledsp) === null || _a === void 0 ? void 0 : _a.toString();
    try {
        return {
            gamePlayInfoId: ledsp ? (_b = (0, ledsp_encoding_1.QSDecode)(ledsp)) === null || _b === void 0 ? void 0 : _b.id : undefined,
            demoId: (_c = parsed === null || parsed === void 0 ? void 0 : parsed.demoId) === null || _c === void 0 ? void 0 : _c.toString(),
        };
    }
    catch (error) {
        console.error("Ledsp query string is invalid", error);
    }
    return {
        gamePlayInfoId: "--null--",
        demoId: (_d = parsed === null || parsed === void 0 ? void 0 : parsed.demoId) === null || _d === void 0 ? void 0 : _d.toString(),
    };
}
exports.default = useLedspParams;
