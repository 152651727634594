import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import { useFloating } from '@floating-ui/react-dom';
import buildAssetPath from '../../utils/buildAssetPath';

const DebriefingSection = ({ children, groupId = null, title, subTitle, data = null }) => {
  const { x, y, reference, floating, strategy } = useFloating();

  return (
    <InView threshold={0.4} triggerOnce={false}>
      {({ inView, ref }) => (
        <motion.div
          ref={ref}
          className="flex flex-col w-screen h-screen p-3 2xl:p-4 3xl:p-8"
        >
          <div className="relative flex flex-row w-full space-x-8">
            <motion.h2 className="relative h-16 overflow-y-hidden text-2xl font-black text-blue-100 3xl:h-20 2xl:text-3xl 3xl:text-5xl">
              {title.split('').map((character: string, characterIndex) => (
                <motion.span
                  key={characterIndex}
                  initial={{ opacity: 0, y: 40 }}
                  animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
                  transition={
                    inView
                      ? { delay: 0.7 + characterIndex / 40 }
                      : { delay: 0.1 }
                  }
                  className="relative inline-block"
                >
                  {character === ' ' ? (
                    <span className="block w-4 3xl:w-5" />
                  ) : (
                    character
                  )} 
                </motion.span>
              ))} 
            </motion.h2>
            <motion.span
              className="relative group"
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ delay: 1 }}
            >
              <img
                ref={reference}
                src={buildAssetPath('images/info.png')}
                alt="Info"
                className="w-10 h-10 cursor-help"
              />

              <span
                ref={floating}
                className="z-50 p-4 text-xs leading-tight text-white transition-all duration-200 ease-in-out border-b-2 rounded-lg shadow-lg opacity-0 pointer-events-none bg-black/[.75] backdrop-blur-lg border-black/50 xl:text-sm 2xl:text-base 3xl:text-lg w-96 group-hover:opacity-100"
                style={{
                  textShadow: '0px 2px 2px rgba(0, 0, 0, 0.4)',
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
              >
                {subTitle}
              </span>
            </motion.span>
           <motion.span className="relative h-16 overflow-y-hidden text-2xl font-black text-blue-100 3xl:h-20 2xl:text-3xl 3xl:text-5xl"  initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ delay: 1 }}> {groupId!=null?`[${groupId}]`:""}</motion.span>
            <motion.span
              className="absolute right-0 h-16 overflow-y-hidden text-2xl font-black text-blue-100 3xl:h-20 2xl:text-3xl 3xl:text-5xl"
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ delay: 1 }}
            >
              {data}
            </motion.span>
          </div>
          <motion.div
            className="relative flex flex-row items-center justify-center flex-grow w-full overflow-y-hidden bg-blue-100 rounded-xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ delay: 2, duration: 1 }}
          >
            <img
              className="absolute w-36 left-6 top-6"
              src={buildAssetPath('images/logo_bocconi_positive.png')}
              alt="Boccini logo"
            />
            {children}
          </motion.div>
        </motion.div>
      )}
    </InView>
  );
};

export default DebriefingSection;
