import { useState } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { useMessages } from '../../store/messages';
import _Switch from '../layout/switch';

const labels = [
  'lossByMissingActions',
  'lossByMissingPUActions',
  'lossByMissingPEOUAction',
  'lossByNotFittingActions',
  'lossByEntropy',
];

const PerformanceAdjustmentFilters = ({
  data,
  benchmarkData,
  hoveredLoss,
  displayedLosses,
  handleUpdate,
  handleHover = (loss: string) => {},
  handleLeave = (loss: string) => {},
}) => {
  const t = useMessages(({ print }) => print);
  const [activeTab, setActiveTab] = useState<0 | 1>(0);

  const extractAvg = (list, key: string) => ({
    key,
    value: list.reduce((acc, o) => (acc += Number(o[key])), 0) / list.length,
  });

  const getAverageData = samples => [
    extractAvg(samples, 'lossByMissingActions'),
    extractAvg(samples, 'lossByMissingPUActions'),
    extractAvg(samples, 'lossByMissingPEOUAction'),
    extractAvg(samples, 'lossByEntropy'),
    extractAvg(samples, 'lossByNotFittingActions'),
  ];

  const avgData = getAverageData(data);
  const globalAvgData = getAverageData(benchmarkData);

  const min = avgData.reduce((seed, item) => {
    return seed && seed.value < item.value ? seed : item;
  }, null);
  const max = avgData.reduce((seed, item) => {
    return seed && seed.value > item.value ? seed : item;
  }, null);

  const globalMin = globalAvgData.reduce((seed, item) => {
    return seed && seed.value < item.value ? seed : item;
  }, null);
  const globalMax = globalAvgData.reduce((seed, item) => {
    return seed && seed.value > item.value ? seed : item;
  }, null);

  return (
    <div className="w-1/5 p-3 xl:p-4 mt-32 ml-3 xl:ml-6 mr-auto  rounded-lg shadow-2xl left-8 bg-white/40 border-t-2 border-white backdrop-blur-sm shadow-black/5 self-start">
      <div id="tabs" className="flex space-x-2 justify-between">
        <Tab
          isActive={activeTab === 0}
          onClick={() => setActiveTab(0)}
          label={t('variables')}
        />
        <Tab
          isActive={activeTab === 1}
          onClick={() => setActiveTab(1)}
          label={t('key-values')}
        />
      </div>

      <div id="content" className="my-6 flex flex-col space-y-6">
        {activeTab === 0 &&
          labels.map(labelId => (
            <_Switch
              key={labelId}
              title={t(`${labelId}-title`)}
              description={t(`${labelId}-desc`)}
              isEnabled={displayedLosses.includes(labelId)}
              hide={hoveredLoss && hoveredLoss !== labelId}
              handleHover={() => handleHover(labelId)}
              handleLeave={() => handleLeave(labelId)}
              handleSwitch={(isChecked: boolean) =>
                handleUpdate(draft =>
                  isChecked
                    ? [...draft, labelId]
                    : draft.filter(label => label !== labelId)
                )
              }
            />
          ))}
        {activeTab === 1 && (
          <>
            <p className="my-2 font-light uppercase text-slate-600">
              {t('this-session')}
            </p>

            <span className="mb-4 ml-4">
              <p className="text-xs font-bold uppercase xl:text-sm text-slate-700">
                {t('less-impact-variable')} <br />{' '}
              </p>
              <p className="text-sm font-light xl:text-base text-slate-900">
                {t(`${min.key}-title`)}: {Math.round(min.value * 100)}%
              </p>
            </span>

            <span className="my-4 ml-4">
              <p className="text-xs font-bold uppercase xl:text-sm text-slate-700">
                {t('most-impact-variable')} <br />{' '}
              </p>
              <p className="text-sm font-light xl:text-base text-slate-900">
                {t(`${max.key}-title`)}: {Math.round(max.value * 100)}%
              </p>
            </span>

            <p className="my-2 font-light uppercase text-slate-600">
              Benchmark
            </p>

            <span className="mb-4 ml-4">
              <p className="text-xs font-bold uppercase xl:text-sm text-slate-700">
                {t('less-impact-variable')} <br />{' '}
              </p>
              <motion.p className="text-sm font-light xl:text-base text-slate-900">
                {t(`${globalMin.key}-title`)}:{' '}
                {Math.round(globalMin.value * 100)}%
              </motion.p>
            </span>

            <span className="my-4 ml-4">
              <p className="text-xs font-bold uppercase xl:text-sm text-slate-700">
                {t('most-impact-variable')} <br />{' '}
              </p>
              <motion.p className="text-sm font-light xl:text-base text-slate-900">
                {t(`${globalMax.key}-title`)}:{' '}
                {Math.round(globalMax.value * 100)}%
              </motion.p>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default PerformanceAdjustmentFilters;

const Tab = ({ label, onClick, isActive = false }) => (
  <div
    className={clsx(
      'uppercase text-blue-900/90 text-sm font-light pb-1 xl:pb-2',
      isActive ? 'border-b-2 border-blue-900/90' : ''
    )}
    onClick={onClick}
  >
    {label}
  </div>
);
