import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import buildAssetPath from '../../utils/buildAssetPath';

interface IProps {
  setIsLandingPage: (state: boolean) => void;
}

const LandingSection = ({ setIsLandingPage }: IProps) => {
  const [ref, inView, entry] = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    setIsLandingPage(!!inView);
  }, [inView]);

  return (
    <div className="section" ref={ref}>
      <div className="flex flex-col w-screen h-screen">
        <div className="select-none w-full bg-transparent grid grid-cols-3 grid-rows-1 gap-x-8 px-8 lg:px-10 xl:px-12 2xl:px-16 py-2 z-50 h-[100px]">
          <img
            src={buildAssetPath('images/logo_bocconi.png')}
            alt="Logo Bocconi"
            className="my-auto mr-auto h-1/2 _nodrag"
          />
          <img
            src={buildAssetPath('images/logo.png')}
            alt="Logo Bocconi"
            className="mx-auto my-auto h-1/2 _nodrag"
            style={{ imageRendering: '-webkit-optimize-contrast' }}
          />
          <img
            src={buildAssetPath('images/logo_built_internal.png')}
            alt="Logo Bocconi"
            className="my-auto ml-auto h-1/2 _nodrag"
            style={{
              filter: 'drop-shadow(2px 4px 6px black)',
            }}
          />
        </div>
        <h2 className="m-auto font-black text-center text-blue-100 text-7xl">
          I Change U - Debriefing
        </h2>
      </div>
    </div>
  );
};

export default LandingSection;
