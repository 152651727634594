import PocketBase from 'pocketbase';

export default class PocketbaseClient {
  client: PocketBase;

  constructor(configuration: IPocketbaseConfiguration) {
    this.client = new PocketBase(configuration.url);

    this.client.beforeSend = (url: string, requestConfiguration: any) => {
      delete requestConfiguration.signal;

      return requestConfiguration;
    };
  }

  public async create(collection: string, _id: string, payload: any) {
    this.client.records.create(collection, {
      ...payload,
      _id: _id,
    });
  }

  public async update(collection: string, id: string, payload: any) {
    const existingRecord = await this.findOne(collection, id);

    if (!existingRecord)
      throw new Error('Record does not exist. RecordId: ' + id);

    this.client.records.update(collection, existingRecord.id, {
      ...payload,
      _id: existingRecord._id,
    });
  }

  public async findOne(collection: string, id: string) {
    try {
      console.log(`finding \`${collection}\` record with id \`${id}\`...`);

      const result = await this.client.records.getFullList(collection, 1, {
        filter: `_id = "${id}"`,
      });

      return result[0];
    } catch (error) {
      return undefined;
    }
  }

  public async findAll(collection: string, filters?: Record<string, any>) {
    let pocketbaseFilters = filters.startingWith
      ? {
          filter: `${filters.startingWith[0]} ~ "${filters.startingWith[1]}"`,
        }
      : undefined;

    return await this.client.records.getFullList(
      collection,
      400, // NOTE: This is an hard limit imposed By Pocketbase. Handle internally if more than 400 records are exepcted to be found at once.
      pocketbaseFilters
    );
  }

  public async upsert(collection: string, id: string, payload: any) {
    const existingRecord = await this.findOne(collection, id);

    if (existingRecord) this.update(collection, id, payload);
    else this.create(collection, id, payload);
  }

  public async delete(collection: string, id: string) {
    const existingRecord = await this.findOne(collection, id);

    this.client.records.delete(collection, existingRecord.id);
  }

  public subscribe(
    collection: string,
    recordId: string | undefined,
    callback: (event: any) => void
  ) {
    this.client.realtime.subscribe(
      `${collection}${recordId ? `/${recordId}` : ''}`,
      event => callback(event.record)
    );
  }

  public unsubscribe(collection: string, recordId: string | undefined) {
    this.client.realtime.unsubscribe(
      `${collection}${recordId ? `/${recordId}` : ''}`
    );
  }
}

export interface IPocketbaseConfiguration {
  url: string;
}
