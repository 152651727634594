import clsx from 'clsx';
import { useMessages } from '../../store/messages';
import { scenarioLabels } from './scenario-labels';

const ARBudgetFilters = ({
  displayedScenarios,
  scenariosDistinguished,
  handleUpdate,
  handleDistinguishScenarios,
  benchmarksShown,
  toggleBenchmarks,
}) => {
  const t = useMessages(({ print }) => print);

  return (
    <div className="relative flex flex-col p-4 my-auto ml-4 mr-auto space-y-6 rounded-lg shadow-2xl min-w-max left-8 bg-white/50 backdrop-blur-sm shadow-black/10">
      <div
        onClick={() => handleDistinguishScenarios()}
        className="px-2 py-1 m-auto rounded cursor-pointer hover:bg-blue-100 w-max peer"
      >
        {scenariosDistinguished
          ? t('slide-2-hide-scenarios')
          : t('slide-2-reveal-scenarios')}
      </div>

      {!scenariosDistinguished && (
        <span className="absolute z-50 w-64 p-2 text-xs text-white transition-all duration-200 ease-in-out rounded-lg shadow-xl opacity-0 pointer-events-none peer-hover:opacity-100 -right-24 top-10 bg-black/80 backdrop-blur-sm 2xl:text-sm 3xl:p-4">
          {t('slide-2-scenarios-toggle-tooltip')}
        </span>
      )}

      {scenariosDistinguished && (
        <>
          <div className="font-bold text-sky-900">{t('filters')}</div>
          <div className="flex flex-col space-y-4">
            {scenariosDistinguished &&
              Object.keys(scenarioLabels).map(scenario => (
                <div
                  key={scenario}
                  className="flex flex-row items-center space-x-2 cursor-pointer"
                  onClick={() =>
                    handleUpdate(
                      !displayedScenarios.includes(scenario)
                        ? [...displayedScenarios, scenario]
                        : displayedScenarios.filter(label => label !== scenario)
                    )
                  }
                >
                  <div
                    className={clsx(
                      'text-gray-500 border-2 h-6 w-6',
                      scenario === 'neutral'
                        ? 'rounded-full border-blue-700'
                        : 'rounded border-purple-700',
                      displayedScenarios.includes(scenario) &&
                        scenario === 'neutral'
                        ? 'bg-blue-700'
                        : displayedScenarios.includes(scenario) &&
                          scenario === 'crisis'
                        ? 'bg-purple-700'
                        : ''
                    )}
                    style={{
                      filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2))',
                    }}
                  />
                  <span className="text-sm">
                    Scenario "{scenarioLabels[scenario]}"
                  </span>
                </div>
              ))}
          </div>
        </>
      )}

      <hr />

      <div
        className="flex flex-row items-center space-x-2 cursor-pointer"
        onClick={toggleBenchmarks}
      >
        <div
          className={clsx(
            'text-gray-500 border-2 h-6 w-6',
            benchmarksShown
              ? 'rounded-full border-orange-700 bg-orange-700'
              : 'rounded-full border-orange-700'
          )}
          style={{
            filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2))',
          }}
        />
        <span className="text-sm peer">{t('show-benchmarks')}</span>
        <div className="absolute z-50 w-64 p-2 text-xs text-white transition-all duration-200 ease-in-out rounded-lg shadow-xl opacity-0 pointer-events-none peer-hover:opacity-100 -right-24 top-10 bg-black/80 backdrop-blur-sm 2xl:text-sm 3xl:p-4">
          {t('ar-budget-benchmark-toggle-tooltip')}
        </div>
      </div>
    </div>
  );
};

export default ARBudgetFilters;
