import { useState } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { useMessages } from '../store/messages';

interface IProps {
  fullpageApi: any;
}

const PresentationToolbar = ({ fullpageApi }: IProps) => {
  const t = useMessages(({ print }) => print);

  const [isMouseOver, setMouseOver] = useState<boolean>(false);

  const activeSection = fullpageApi?.getActiveSection()?.index();

  return (
    <>
      <motion.div
        className="fixed top-0 bottom-0 z-50 flex flex-col justify-center p-4 my-auto space-x-2 transition-all duration-200 ease-in-out border-b shadow-xl cursor-default h-2/3 xl:h-1/2 w-max xl:space-y-4 rounded-xl bg-blue-100/50 border-blue-200/90 right-4 backdrop-blur-sm"
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        animate={{
          x: isMouseOver ? 0 : 300,
          transition: { duration: 0.2, type: 'spring', bounce: 0.6 },
          opacity: isMouseOver ? 1 : 0.9,
        }}
      >
        <p
          className={clsx(
            'font-medium text-center mx-auto text-white uppercase text-xs absolute transition-all duration-200 ease-in-out -rotate-90 top-1/2 -left-2',
            isMouseOver ? 'opacity-0' : 'opacity-100'
          )}
        >
          {t('index')}
        </p>
        <ToolbarItem
          isActive={isMouseOver && activeSection === 0}
          handleClick={() => fullpageApi?.moveTo(1)}
          label={t('slide-1-title')}
        />
        <ToolbarItem
          isActive={isMouseOver && activeSection === 1}
          handleClick={() => fullpageApi?.moveTo(2)}
          label={t('slide-2-title')}
        />
        <ToolbarItem
          isActive={isMouseOver && activeSection === 2}
          handleClick={() => fullpageApi?.moveTo(3)}
          label={t('slide-3-title')}
        />
        <ToolbarItem
          isActive={isMouseOver && activeSection === 3}
          handleClick={() => fullpageApi?.moveTo(4)}
          label={t('slide-4-title')}
        />
        <ToolbarItem
          isActive={isMouseOver && activeSection === 4}
          handleClick={() => fullpageApi?.moveTo(5)}
          label={t('slide-5-title')}
        />
        <ToolbarItem
          isActive={isMouseOver && activeSection === 5}
          handleClick={() => fullpageApi?.moveTo(6)}
          label={t('slide-6-title')}
        />
        <ToolbarItem
          isActive={isMouseOver && activeSection === 6}
          handleClick={() => fullpageApi?.moveTo(7)}
          label={t('slide-7-title')}
        />
        <ToolbarItem
          isActive={isMouseOver && activeSection === 7}
          handleClick={() => fullpageApi?.moveTo(8)}
          label={t('slide-8-title')}
        />
        <ToolbarItem
          isActive={isMouseOver && activeSection === 8}
          handleClick={() => fullpageApi?.moveTo(9)}
          label={t('slide-9-title')}
        />
      </motion.div>
    </>
  );
};

const ToolbarItem = ({ isActive, handleClick, label }) => (
  <button
    className={clsx(
      'text-sm leading-none cursor-pointer hover:scale-105 active:scale-95 transition-all duration-100 ease-in-out font-medium px-4 py-2 rounded text-left',
      isActive
        ? 'bg-blue-900/50  text-white'
        : 'text-blue-900 hover:bg-blue-200'
    )}
    onClick={() => handleClick(9)}
  >
    {label}
  </button>
);

export default PresentationToolbar;
